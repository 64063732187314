<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Aktuelle Veranstaltungen">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="handleRegionChange"
        ></region-chooser>
        <el-button type="primary" icon="fal fa-plus" @click="newEvent">
          Neue Veranstaltung
        </el-button>
      </template>

      <el-input
        v-model="computedSearchQuery"
        placeholder="Durchsuchen…"
        clearable
        style="max-width: 500px;"
      >
        <i slot="prefix" class="el-icon-search el-input__icon"></i>
      </el-input>

      <data-loader ref="dataloader" :endpoint="endpoint">
        <template #loaded="{data}">
          <events-table
            :events="data.events"
            :view-as="viewAs"
            :mode="mode"
            @on-delete="reloadData"
          ></events-table>

          <the-pagination
            v-model="currentPage"
            :total-count="data.total_count"
          ></the-pagination>
        </template>
      </data-loader>
    </page-content>
  </div>
</template>

<script>
import * as log from "loglevel"
import { debounce } from "lodash-es"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DataLoader from "@/components/DataLoader"
import ThePagination from "@/components/ThePagination"
import EventsTable from "@/components/EventsTable"

export default {
  metaInfo: {
    title: "Aktuelle Veranstaltungen"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    DataLoader,
    ThePagination,
    EventsTable
  },
  beforeRouteUpdate(to, from, next) {
    log.info("view: beforeRouteUpdate")

    if (from.name === to.name) {
      if (to.query.page) {
        log.info(`setting current page to ${to.query.page}`)
        this.currentPage = parseInt(to.query.page)
      } else {
        log.info(`setting current page to 1`)
        this.currentPage = 1
      }

      if (to.query.query) {
        log.info(`setting query to ${to.query.query}`)
        this.localSearchQuery = to.query.query
        this.remoteSearchQuery = to.query.query
      } else {
        log.info(`setting query to ""`)
        this.localSearchQuery = ""
        this.remoteSearchQuery = ""
      }

      if (to.query.organizationID) {
        this.organizationID = to.query.organizationID
      } else {
        this.organizationID = ""
      }
    }
    next()
  },
  data() {
    return {
      mode: "",
      currentPage: parseInt(this.$route.query.page) || 1,
      localSearchQuery: this.$route.query.query || "",
      remoteSearchQuery: this.$route.query.query || "",
      organizationID: this.$route.query.organizationID || ""
    }
  },
  computed: {
    computedSearchQuery: {
      get() {
        return this.localSearchQuery
      },
      set(value) {
        this.localSearchQuery = value
        this.debouncedSearch(value)
      }
    },
    endpoint() {
      if (this.$store.getters["auth/isOrganizationUser"]) {
        return `/events?mode=${this.mode}&page=${this.currentPage}&query=${this.remoteSearchQuery}`
      } else if (this.$store.getters["auth/isSuperAdmin"]) {
        return `/admin/events?mode=${this.mode}&page=${this.currentPage}&query=${this.remoteSearchQuery}&region_id=${this.$store.state.auth.chosenRegion}&organization_id=${this.organizationID}`
      } else {
        return `/admin/events?mode=${this.mode}&page=${this.currentPage}&query=${this.remoteSearchQuery}&organization_id=${this.organizationID}`
      }
    },
    viewAs() {
      if (this.$store.getters["auth/isOrganizationUser"]) {
        return "organization"
      } else {
        return "region"
      }
    }
  },
  methods: {
    handleRegionChange() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: 1,
          query: "",
          region_id: this.$store.state.auth.chosenRegion
        }
      })
    },
    debouncedSearch: debounce(
      async function(query) {
        log.info(`Searching for "${query}"…`)
        this.remoteSearchQuery = query
        this.currentPage = 1
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: 1,
            query: query
          }
        })
      },
      300,
      { maxWait: 3000 }
    ),
    newEvent() {
      this.$router.push({ name: "NewEventPage" })
    },
    reloadData() {
      this.$refs.dataloader.loadData()
    }
  }
}
</script>
